import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
import SideTextSection from '../components/sideTextSection/sideTextSection'
import ImageSection from '../components/imageSection/imageSection'
  
export const query = graphql`
  query PrivacyPolicy {
    prismic {
      allText_pages(uid: "privacy-policy") {
        edges {
          node {
            title
            body {
              ... on PRISMIC_Text_pageBodyText_section {
                type
                label
                primary {
                  section_text
                  section_title
                }
              }
              ... on PRISMIC_Text_pageBodyImage {
                type
                label
                primary {
                  image
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const PrivacyPolicy = ({ data }) => {
  const doc = data.prismic.allText_pages.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title={RichText.asText(doc.node.title)} />
      <div style={{
        overflow: "auto"
      }}>
        <PageHeaderSlim title={doc.node.title} />
        {doc.node.body !== null ? doc.node.body.map((section, index) => {
          switch (section.type) {
            case 'text_section':
              return (
                <SideTextSection section={section} key={index} />
              )
            case 'image':
              return (
                <ImageSection section={section} key={index} />
              )
            default:
              return null
          }
        }) : null}
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
